





























































































































































































































































import {
  defineComponent,
  useContext,
  ref,
  onMounted,
} from '@nuxtjs/composition-api';
import type { Breadcrumb } from '~/modules/catalog/types';
import Breadcrumbs from '~/components/Layouts/Breadcrumbs/index.vue';

import { getMetaInfo } from '~/helpers/getMetaInfo';

import { useAuthenticationStore } from '~/modules/authentication/stores/authentication';

import { useUser } from '~/modules/customer/composables/useUser';

import { setItem } from '~/helpers/asyncLocalStorage';

const AUTHENTICATION_PAGE_HEAD = {
  meta_title: 'Modern Squared | Authentication',
  meta_description: 'This is the authentication page of Modern Squared',
};

const pageMeta = getMetaInfo(AUTHENTICATION_PAGE_HEAD);

export default defineComponent({
  components: {
    Breadcrumbs,
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      if (from && from.fullPath !== '/') {
        // eslint-disable-next-line no-param-reassign
        vm.previousLink = from?.fullPath;
      }
    });
  },
  middleware: 'not-authenticated',
  setup() {
    const { localePath } = useContext();
    const {
      socialAuthenticationGOOGLE,
      socialAuthenticationFACEBOOK,
      socialAuthenticationAMAZON,
      redirectURIbuilder,
    } = useUser();

    const authenticationStore = useAuthenticationStore();

    const previousLink = ref(null);
    const showWelcome = ref(false);

    const REFmainSocials = ref(null);

    const signedUpUser = ref({
      email: '',
      firstname: 'Veronica',
      lastname: '',
    });

    const breadcrumbs = ref<Breadcrumb[]>([
      {
        text: 'log in / register',
        link: localePath('/authentication/login'),
      },
    ]);

    const prefixBreadcrumbs = ref<Breadcrumb[]>([
      {
        text: 'home',
        link: localePath('/'),
      },
    ]);

    const setPreviousLink = async () => {
      if (authenticationStore.previousLink) {
        await setItem('previous-link', authenticationStore.previousLink);
      }
    };

    const authenticationInitGOOGLE = async () => {
      await setPreviousLink();
      const platform = 'google';
      const REDIRECT_URI = redirectURIbuilder(platform);
      socialAuthenticationGOOGLE(REDIRECT_URI);
    };

    const authenticationInitFACEBOOK = async () => {
      await setPreviousLink();
      const platform = 'facebook';
      const REDIRECT_URI = redirectURIbuilder(platform);
      socialAuthenticationFACEBOOK(REDIRECT_URI);
    };

    const authenticationInitAMAZON = async () => {
      await setPreviousLink();
      const platform = 'amazon';
      const REDIRECT_URI = redirectURIbuilder(platform);
      socialAuthenticationAMAZON(REDIRECT_URI);
    };

    const signupUser = (user: any) => {
      signedUpUser.value = user;
      showWelcome.value = true;
    };

    const jumpToSocialLogin = () => {
      REFmainSocials.value?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    };

    const buildPreviousLink = () => {
      if (previousLink.value) {
        const ORIGIN = window.location.origin;
        const PREVIOUS_BUILT_URL = `${ORIGIN}${previousLink.value}`;
        authenticationStore.$patch((state) => {
          state.previousLink = PREVIOUS_BUILT_URL;
        });
      }
    };

    onMounted(() => {
      buildPreviousLink();
    });

    return {
      previousLink,
      authenticationInitGOOGLE,
      authenticationInitFACEBOOK,
      authenticationInitAMAZON,
      signupUser,
      signedUpUser,
      showWelcome,
      prefixBreadcrumbs,
      breadcrumbs,
      REFmainSocials,
      jumpToSocialLogin,
    };
  },
  head() {
    return {
      ...pageMeta,
    };
  },
});
