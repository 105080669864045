import { defineStore } from 'pinia';

interface AuthenticationState {
  previousLink: string | null;
}

export const useAuthenticationStore = defineStore('authentication', {
  state: (): AuthenticationState => ({
    previousLink: null,
  }),
});
